.App { }

.spinner {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .spinner {
    animation: App-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#playerContainer,
  .player {
      width: 100%;
      height: auto;
      min-height: 400px;
      background-color: black;
      outline: none;
  }
  .vjs-big-play-button {
      display: none !important;
  }

#video-select {
  margin-bottom: 10px;
}